
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import { MoodScore } from "@/interfaces/therapy";
import SimpleButton from "./SimpleButton.vue";
import { post } from "@/api";
import { timeDiff } from "@/i18n-setup";
import i18n from "@/i18n";

@Component({
  components: {
    SimpleButton,
  },
})
export default class MoodScoreSurvey extends Vue {
  moodScore = 5;

  @Prop({
    default: function() {
      return null;
    },
  })
  isDataSubmited!: boolean;

  lastSurvey?: MoodScore;
  lastUpdated: Date = new Date();
  dateUpdater = setInterval(() => {
    this.lastUpdated = new Date();
  }, 60 * 1000);

  loaded = false;

  justSent = false;

  get past(): string {
    if (!this.lastSurvey) {
      return "";
    }
    return timeDiff(
      i18n,
      new Date(this.lastSurvey.time).getTime() - this.lastUpdated.getTime()
    );
  }

  async sendSurvey() {
    const { data: survey } = await post.sendSurvey(
      +new Date(),
      this.moodScore,
      1
    );
    this.lastSurvey = survey;
    this.lastUpdated = new Date();
    this.justSent = true;
    this.$emit("new-survey", survey);
    this.$emit("update:isDataSubmited", true);
  }

  async getLastSurvey() {
    const { data: survey } = await post.moodScores("mood_score", 1);
    this.lastSurvey = survey[0];
    this.loaded = true;
  }

  async mounted() {
    await this.getLastSurvey();
  }
}
