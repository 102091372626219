
import { Vue, Component } from "vue-property-decorator";
import NextVisitWidget from "@/components/NextVisitWidget.vue";
import DailyTestWidget from "@/components/DailyTestWidget.vue";
import NewMessageWidget from "@/components/NewMessageWidget.vue";
import MoodScoreSurvey from "@/components/MoodScoreSurvey.vue";

@Component({
  components: {
    NextVisitWidget,
    DailyTestWidget,
    NewMessageWidget,
    MoodScoreSurvey,
  },
})
export default class PatientDashboard extends Vue {
  isDataSubmited = false;
}
