
import { Vue, Component } from "vue-property-decorator";
import TherapistVisitsPending from "@/views/dashboard/visits/TherapistVisitsPending.vue";

@Component({
  components: {
    TherapistVisitsPending,
  },
})
export default class TherapistDashboard extends Vue {}
