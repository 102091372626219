
import Vue from "@/interfaces/vue";
import { Component, Prop, Watch } from 'vue-property-decorator';
import LineChart from './LineChart.vue';
import { Therapy } from '@/interfaces';
import { get } from "@/api";

@Component({
  components: {
    LineChart
  }
})
export default class DailyTestWidget extends Vue {
  @Prop({
    default: function() {
      return null;
    },
  })
  isDataSubmited!: boolean;

  loaded = false;
  error = null;
  chartdata = {};
  options = {
    legend: {
      display: false
    },
    scales: {
      yAxes: [{
        ticks: {
          precision: 0,
          beginAtZero: true,
          max: 10
        }
      }]
    }
  };

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      const { data } = await get.moodData("mood_score", 7);
      const surveys = data.map((x) => Object.assign(x, { time: new Date(x.time) }));
      const labels: string[] = [];
      const values: number[] = [];
      surveys.forEach(function(survey: Therapy.MoodScore) {
        labels.push(survey.time.toLocaleDateString());
        values.push(survey.value);
      });
      this.chartdata = {
        labels: labels,
        datasets: [
          {
            backgroundColor: '#DBE7F8',
            data: values
          }
        ]
      };
    } catch (err) {
      this.error = err;
      console.log(this.error);
    }
    this.loaded = true;
  }

  @Watch('isDataSubmited')
  refreshWidget(){
    if (this.isDataSubmited) {
      this.$emit("update:isDataSubmited", false);
      this.loaded = false;
      this.fetchData();
    }
  }
}
