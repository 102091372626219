
import Vue from "@/interfaces/vue";
import { Component } from "vue-property-decorator";
import VisitsListElem from "@/views/dashboard/visits/VisitsListElem.vue";
import { Session } from "@/interfaces/therapy";
import { get } from "@/api";

@Component({
  components: {
    VisitsListElem,
  },
})
export default class NextVisitWidget extends Vue {
  visit: null | Session = null;

  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    const { data } = await get.sessions(false);
    console.log(data);
    if (data && data.length > 0) {
      this.visit = data.filter(
        (x) => !x.cancelled && x.accepted_by_therapist
      )[0];
    }
  }
}
