
import Vue from "@/interfaces/vue";
import { Message, Messages, Therapy } from "@/interfaces/therapy";
import { UserName } from "@/interfaces/user";
import { Component } from "vue-property-decorator";
import SimpleButton from "./SimpleButton.vue";
import { get } from "@/api";

@Component({
  components: {
    SimpleButton,
  },
})
export default class NewMessageWidget extends Vue {
  lastMessage: Message | null = null;
  lastAuthorName: UserName | null = null;
  lastTherapyId: number | null = null;

  truncate(text: string, len: number, suf: string): string {
    return text.substring(0, len) + (text.length > len ? suf : "");
  }

  // async fetchData() {
  //   const therapies = await this.$store.state.api.getTherapies();
  //   await get.therapies();
  //   if (therapies) {
  //     for (const therapy of therapies) {
  //       const messages: Messages = await this.$store.state.api.getMessages(
  //         therapy.id
  //       );
  //       if (
  //         messages &&
  //         messages.results.length > 0 &&
  //         messages.results[0].author !== this.$store.state.user.id &&
  //         (!this.lastMessage ||
  //           new Date(this.lastMessage.created) <
  //             new Date(messages.results[0].created))
  //       ) {
  //         this.lastMessage = messages.results[0];
  //         this.lastAuthorName = this.$store.state.user.is_therapist
  //           ? therapy.user_name
  //           : therapy.therapist_name;
  //         this.lastTherapyId = therapy.id;
  //       }
  //     }
  //   }
  // }
}
