
import { Vue, Component } from "vue-property-decorator";
import PatientDashboard from "./patient/PatientDashboard.vue";
import TherapistDashboard from "./TherapistDashboard.vue";
import { Fragment } from "vue-fragment";
import DesktopInfoFillingView from "./DesktopInfoFillingView.vue";
import i18n from "@/i18n";
const pageKey = "dashboard";

@Component({
  components: {
    PatientDashboard,
    TherapistDashboard,
    DesktopInfoFillingView,
    Fragment,
  },
  metaInfo: {
    title: i18n.t(`page-title.${pageKey}`).toString(),
    meta: [
      {
        name: "description",
        content: i18n.t(`meta-description.${pageKey}`).toString(),
      },
    ],
  },
})
export default class DashboardView extends Vue {}
